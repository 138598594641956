<template>
    <div>
    </div>
</template>
<script>
import Vue from 'vue';
import QRCode from 'qrcodejs2'
import { Tab, Tabs, Button, NavBar, Card, PullRefresh, Toast, Calendar, Popup, Form, Field, Cell, CellGroup, RadioGroup, Radio, Empty, Dialog } from 'vant';

export default {
    name: 'OrderDetail',

    data() {
        return {}
    },

    mounted() {


    },
    filters: {

    },
    methods: {

    },
};
</script>
<style  scoped>

</style>