<template>
    <div style="background-image: url('http://cwr.cloudjca.com/报名背景.jpg');background-size: cover;width: 100vw;height: 100vh;" ref="container">
        <div style="position: absolute;width: 90vw; ;margin-left: 5vw;">
            <van-form ref='form' style="margin-top: 7vh">
                <van-field input-align="left" label-align="left" size="large" name="队伍名称" label="队伍名称" v-model = "form.teamName" placeholder="请输入队伍名称" style="font-family: 'MyCustomFont'" :rules="[{ required: true},{validator: (val) => { return /^[\u4e00-\u9fa5_a-zA-Z0-9_]{1,6}$/.test(val)}}]"/>
                <van-field input-align="left" label-align="left" size="large" name="队长姓名" label="队长姓名" v-model='form.leaderName' placeholder="请输入队长姓名" style="font-family: 'MyCustomFont'" :rules="[{ required: true},{validator: (val) => { return /^[\u4e00-\u9fa5]{1,6}$/.test(val)}}]"/>
                <van-field input-align="left" label-align="left" size="large" name="QQ号" label="QQ号" v-model='form.qqNumber' placeholder="请输入QQ号" style="font-family: 'MyCustomFont'" :rules="[{ required: true},{validator: (val) => { return /^[0-9]{1,15}$/.test(val)}}]"/>
                <van-field input-align="left" label-align="left" size="large" name="赛区" readonly clickable :value="form.area" label="赛区" style="font-family: 'MyCustomFont'" placeholder="请选择赛区" @click="showPicker = true" />
                <van-popup v-model="showPicker" position="bottom">
                    <van-picker size="large" style="font-family: 'MyCustomFont'"
                                show-toolbar
                                :columns="columns"
                                :rules="[{ required: true},{validator: (val) => { return /^[\u4e00-\u9fa5]{1,6}$/.test(val)}}]"
                                @confirm="onConfirm"
                                @cancel="showPicker = false"
                    />
                </van-popup>
                <van-field input-align="left" label-align="left" size="large" name="手机号" label="手机号" v-model='form.phone' placeholder="请输入手机号" style="font-family: 'MyCustomFont'" :rules="telRules"/>
                <van-field input-align="left" label-align="left" size="large" name="短信验证码" style="font-family: 'MyCustomFont'"
                           v-model="form.sms_code"
                           center
                           clearable
                           label="短信验证码"
                           placeholder="请输入短信验证码"
                           @input="onPhoneChange"
                >
                    <template #button>
                        <van-button size="small" type="primary" @click.prevent="sendSmsCode" style="font-family: 'MyCustomFont'" :disabled="isSmsSend">{{ sendBtnText }}</van-button>
                    </template>
                </van-field>
            </van-form>
            <div  style="width: 100%; margin-top: 3vh;display: flex;justify-content: center">
                <van-button style="background-color: #00000000;background-position: center;border: none;padding: 0;;background-position: center;background-image: url('http://cwr.cloudjca.com/提交按钮.png');background-size: cover; width: 40vw;font-family: 'MyCustomFont'"  @click="onSubmit">  </van-button>
            </div>
            <div  style="width: 100%; margin-top: 3vh;display: flex;justify-content: center">
                <van-image width="15vh" height="15vh" src="http://cwr.cloudjca.com/code.png" @click="toJinDong"></van-image>
            </div>
            <div  style="width: 100%; margin-top: 3vh;display: flex;justify-content: center">
                <van-image width="60vw"  height="7vh" src="http://cwr.cloudjca.com/逛京东按钮.png" @click="toJinDong"></van-image>
            </div>
            <van-dialog :showConfirmButton="showConfirmButton" v-model="show" style="display: flex;background-color: #00000000;background-position: center;border: none;width: 80vw;height: 30vh;background-image: url('http://cwr.cloudjca.com/报名成功弹窗.png');background-size: cover;font-family: 'MyCustomFont'" >
                <img src="http://cwr.cloudjca.com/关闭.png" style="width:40vw;height: 5vh; margin-left: 20vw;margin-top: 20vh" @click="toPage">

            </van-dialog>
<!--            <van-dialog v-model="show" @confirm="toPage" style="background-color: #00000000;background-position: center;border: none;width: 80vw;height: 50vh;background-image: url('http://cwr.cloudjca.com/报名成功弹窗.png');background-size: cover;font-family: 'MyCustomFont'" ></van-dialog>-->
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Toast, Picker, Notify } from 'vant';
    import axios from 'axios'
    Vue.use(Toast);
    export default {

        name: "WriteMemberInfo",
        data: () => {

            return {
                showButton: true,
                showConfirmButton:false,
                showPicker:false,
                columns:['宁波赛区'],
                form:{
                    area: '宁波赛区'
                },
                code:'',
                // 是否已经发送了验证码
                isSmsSend: false,
                show:false,
                sendBtnText: '点击发送验证码',
                timer: null,
                counter: 60,
                telRules: [{
                    required: true,
                    trigger: 'onBlur'
                }, {
                    // 自定义校验规则
                    validator: value => {
                        return /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                            .test(value)
                    },
                    trigger: 'onBlur'
                }],
            }
        },
        filters: {

        },
        created() {

        },
        methods:{
            toJinDong(){
                window.location.href = 'https://pro.m.jd.com/mall/active/1cLdKuPj1HXyFSaq2JXn1fzysz5/index.html?babelChannel=ttt13';
            },
            onConfirm(value){
                this.form.area = value;
                this.showPicker = false;
            },
            toPage(){
                window.location.href = 'https://pro.m.jd.com/mall/active/1cLdKuPj1HXyFSaq2JXn1fzysz5/index.html?babelChannel=ttt13';
            },
            /**
             * 重置倒计时
             */
            reset () {
                this.isSmsSend = false
                this.sendBtnText = '点击发送验证码'
                if (this.timer) {
                    clearInterval(this.timer)
                    this.counter = 60
                    this.timer = null
                }
            },
            /**
             * 倒计时
             */
            countDown () {
                this.timer = setInterval(() => {
                    this.sendBtnText = `(${this.counter}秒)后重新发送`
                    this.counter--
                    if (this.counter < 0) {
                        this.reset()
                    }
                }, 1000)
            },
            /**
             * 发送验证码
             */
            sendSmsCode () {
                // 判断手机号是否已经输入
                if (!this.form.phone) {
                    Notify({ type: 'warning', message: '请输入手机号' });
                    return false
                }
                // 判断手机号是否符合要求
                if (this.form.phone.length !== 11) {
                    Notify({ type: 'warning', message: '请输入11位手机号' });
                    return false
                }
                axios.get('http://120.25.179.33:8151/api/v1/OpenApi/SendMessage?phone='+ this.form.phone +'&type=0&TeamName=')
                    .then((res) => {
                        if(res.data.success)
                        {
                            // 调用接口，发送短信验证码
                            this.isSmsSend = true
                            // 开始倒计时，60s之后才能再次点击
                            this.countDown()
                            this.code= res.data.code
                        }
                        else
                            Toast(res.data.message)
                    }).catch((err) => {
                    Toast(err.message)
                })
                // this.$axios
                //     .request({
                //         url: "/api/v1/OpenApi/SendMessage",
                //         method: "GET",
                //         data: { Phone: this.form.phone,type:0,TeamName: '' },
                //     })
                //     .then((res) => {
                //         if(res.data.success)
                //         {
                //             // 调用接口，发送短信验证码
                //             this.isSmsSend = true
                //             // 开始倒计时，60s之后才能再次点击
                //             this.countDown()
                //             this.code= res.data.code
                //         }
                //         else
                //             Toast(res.data.message)
                //     }).catch((err) => {
                //     Toast(err.message)
                // })

            },
            // 当手机号变化时，重置发送按钮
            onPhoneChange () {
                this.reset()
            },
            onSubmit(){
                this.$refs.form
                    .validate().then(() => {
                    if(this.code != this.form.sms_code)
                    {
                        Toast('验证码错误')
                        return
                    }
                    this.$axios
                        .request({
                            url: "/api/v1/OpenApi/WriteUserInfo",
                            method: "POST",
                            data: { TeamName: this.form.teamName, LeaderName: this.form.leaderName, QQNumber: this.form.qqNumber, Area: this.form.area, Phone: this.form.phone },
                        })
                        .then((res) => {
                            console.log(res)
                            if(res.data.success){
                                this.show = true
                            }
                        }).catch((err) => {
                        Toast(err.message)
                    })
                }).catch((err) => {
                    console.log(1111111111111111)
                    console.log(err)
                    Toast('请输入正确的' + err[0].name)
                })

            },
            onFocus() {
                const container = this.$refs.container
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
                const rect = container.getBoundingClientRect()
                const top = rect.top + scrollTop
                window.scrollTo(0, top)
            },
            onBlur1() {
                window.scrollTo(0, 0)
            }

        }
    }
</script>

<style scoped>
    .deep-class /deep/ .child-class {

    }

</style>