<template>
    <div  style="background-image: url('http://cwr.cloudjca.com/启动.png');background-size: cover;width: 100vw;height: 100vh;background-color: #48424F">
        <van-progress :percentage="progress" style="position: absolute;width: 80vw;margin-left: 10vw;margin-top: 70vh" :pivot-text="text" @after-enter="onFinish"/>
    </div>
</template>

<script>
    export default {
        name: "jiazai",
        data() {
            return {
                progress:0,
                text:'',
                time:'',
            };
        },
        mounted() {
            setInterval(() => {
                this.time= this.increaseProgress()
            }, 1)

        },
        created() {
            this.increaseProgress()
        },
        methods: {
            increaseProgress() {
                if (this.progress < 100 && this.progress >=0) {
                    this.progress += 0.15; // 每次点击增加10%
                }else if(this.progress >=100){
                    this.progress = -1
                    this.$router.push('/Login');
                }
                else {

                }
            },
            onFinish() {
                // 使用编程式导航跳转页面
                //this.$router.push('/Login');
            }
        },
    }
</script>

<style scoped>

</style>