<template>
    <div style="background-image: url('http://cwr.cloudjca.com/h5首页 - 副本.jpg');background-size: cover;width: 100vw;height: 100vh;">
        <van-button style="border: none;background-color: #00000000;background-position: center;background-image: url('http://cwr.cloudjca.com/活动规则.png');background-size: cover;width: 1vw;font-family: 'MyCustomFont';position: absolute;top: 10vh;right: 10vw;height: 16vh"  @click="showDia=true"></van-button>
        <van-button class="rotating-element" style="background-color: #00000000;background-position: center;border: none;padding: 0;background-image: url('http://cwr.cloudjca.com/报名按钮.png');background-size: cover;position: absolute; top: 80vh; width: 40vw;margin-left: 30vw;font-family: 'MyCustomFont'"  @click="toWriteMes">  </van-button>
        <van-overlay :show="showDia" @click="showDia = false" >
            <div class="block" style="color: white;width: 60vw;margin-left: 20vw;margin-top: 10vh;font-family: 'MyCustomFont'">
                线上赛部分：<br/>
                1.京东显示器高光战力挑战赛活动时间为：2024年5月18日-6月10日。<br/>
                2.具体赛事安排如下：<br/>
                东莞赛区：招募时间5月18日-5月22日，线上海选时间5月23日，二强晋级线下决赛，决赛时间为5月25日。<br/>
                宁波赛区：招募时间6月1日-6月5日，线上海选时间6月8日，二强晋级线下决赛，决赛时间为6月10日。<br/>
                3.赛事规则：海选赛为BO1，半决赛为B01，总决赛为BO3，比赛采用竞技征兆模式。比赛时间报名后由赛事组进行通知。<br/>
                4.赛事奖金：冠军2000元，亚军1000元。（奖金需要缴纳20%个人所得税）<br/>
                线下决赛部分：<br/>
                1.东莞决赛地点：东城街道东莞大道东城段11号台商大厦东莞京东MAL，用户可在活动时间参与当地京东MALL的打卡赢好礼互动，获取丰厚奖品。<br/>
                2.宁波决赛地点：鄞州区四明中路688号宁波京东MALL；决赛时间为6月10日<br/>

                补充:在法律允许的范围内，活动解释权归京东官方所有。</div>
        </van-overlay>
    </div>
</template>
<script>
import axios from 'axios';
import Vue from 'vue';
import { Toast, Dialog } from 'vant';
export default {
    name: 'Login',

    data() {
        return {
            showDia:  false,
        };
    },
    mounted() {

    },

    methods: {
        toWriteMes(){
            this.$router.push('/WriteMemberInfo')
            // Dialog({
            //     title: '',
            //     message: '报名已截止！',
            // }).then(() => {
            //     // on close
            // });
        }
    },
};
</script>
<style  scoped>
    @keyframes rotate {
        0% {
            transform: perspective(1000px) rotateY(0deg);
            animation-timing-function: ease-out;
        }
        50% {
            transform: perspective(1000px) rotateY(180deg);
            animation-timing-function: ease-in;
        }
        100% {
            transform: perspective(1000px) rotateY(360deg);
        }
    }

    .rotating-element {
        animation: rotate 2s linear 1;
    }
</style>