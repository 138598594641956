import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from './axios/api.request';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/main.css'

Vue.use(ElementUI);
import Vant from 'vant';
import { Toast } from 'vant'
import 'vant/lib/index.css';
import wx from 'weixin-js-sdk'
import md5 from 'js-md5' ;

Vue.config.productionTip = true

axios.baseUrl = window.g.baseUrl
axios.timeout = window.g.timeout

Vue.prototype.$EventBus = new Vue();
Vue.prototype.$axios = axios;
Vue.prototype.$md5 = md5;
Vue.prototype.$wx = wx

Vue.prototype.$toast=Toast;
Vue.use(Vant);
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err,err.stack.toString())
  console.log(err.stack.toString()); 
  // for (const key of err.stack) {
  //   console.log(err.stack,key)
  // }
  axios.request({
    method: 'get',
    url: window.g.logBaseUrl + '/wechatlogrecord',
    params: {
      log: err.stack.toString(),
      level: window.g.loglevenl,
      type: "error",
    },
    scccess: (res) => {
      console.log(res)
      console.log(err.stack.toString())
    },
    fail: (errc) => {
      console.log(errc)
    }
  })

}


router.beforeEach((to, from, next) => {
  console.log(to.fullPath, from.fullPath)
  // 非ios设备，切换路由时候进行重新签名
    // 1 .  注入配置
    // wx.config({
    //   debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //   appId: '', // 必填，公众号的唯一标识
    //   timestamp:'' , // 必填，生成签名的时间戳
    //   nonceStr: '', // 必填，生成签名的随机串
    //   signature: '',// 必填，签名
    //   jsApiList: [] // 必填，需要使用的 JS 接口列表
    // });
  if (to.fullPath == '/index' || to.fullPath == '/peopleCenter' || to.fullPath == '/regref') {
    store.dispatch('GETUSERIONFO',{t:true,path:to.fullPath})
    //  let {roles}=  store.getters
  } else {
    store.dispatch('GETUSERIONFO', {t:false,path:to.fullPath})
  }
  next()
  // let authkey = localStorage.getItem("authkey");
  // let merchantType = localStorage.getItem('merchantType');
  // let validityDate = Number(localStorage.getItem("validityDate"));
  // let id = localStorage.getItem('id');
  // let curTime = new Date().getTime();
  // console.log(curTime , validityDate , authkey,curTime <= validityDate) 
  // if (curTime <= validityDate && authkey && id) {
  //   console.log(3123)
  //   if (merchantType == 0) {
  //     let indexc = aa.indexOf(to.fullPath);
  //     indexc >= 0 ? next() : next({path:'/'})
  //   } else if (merchantType == 1) {
  //     let indexc = bb.indexOf(to.fullPath);
  //     indexc >= 0 ? next() : next({path:'/'})
  //   }
  //   // next()
  // } else {
  //   console.log('需要登录')
  //   next()
  // }
})



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
