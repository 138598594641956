import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import YueDetail from '../views/yueDetail.vue'
import YueOder from '../views/yueOder.vue'
import PeopleCenter from '../views/peopleCenter.vue'
import OrderDetail from '../views/orderDetail.vue'
import AddMember from '../views/addMember.vue'
import ModPin from '../views/modPin.vue'
import Dingcan from '../views/dingcan.vue'
import Payorder from '../views/payOrder.vue'
import Login from '../views/login.vue'
import Regref from '../views/regref.vue'
import MemberReg from '../views/memberReg.vue'
import sb from '../views/sb.vue'
import RechargeInfo from '../views/rechargeInfo.vue'
import Userinfo from '../views/userinfo.vue'
import Userface from '../views/userface.vue'
import U404 from '../views/404.vue'
import TicketDetail from "@/views/TicketDetail";
import myOrder from "@/views/myOrder";
import WriteMemberInfo from "@/views/WriteMemberInfo";
import TicketDesc from "@/views/TicketDesc";
import jiazai from "@/views/jiazai";
// import vehicleInfoMaintain from '../views/vehicleInfoMaintain'
Vue.use(VueRouter)

const routes = [{
            path: '/Login',
            name: '登陆',
            component: Login
        },
        {
            path: '/index',
            name: '在线订场',
            component: HomeView
        },
    {
        path: '/',
        name: '加载',
        component: jiazai
    },
    {
        path: '/TicketDetail',
        name: '在线订票',
        component: TicketDetail
    },
        {
            path: '/orderDetail',
            name: 'orderDetail',
            component: OrderDetail
        },
    {
        path: '/TicketDesc',
        name: 'TicketDesc',
        component: TicketDesc
    },
    {
        path: '/WriteMemberInfo',
        name: 'WriteMemberInfo',
        component: WriteMemberInfo
    },
    {
        path: '/myOrder',
        name: 'myOrder',
        component: myOrder
    },
        {
            path: '/peopleCenter',
            name: '个人中心',
            component: PeopleCenter
        },
        {
            path: '/yueDetail',
            name: 'yueDetail',
            component: YueDetail
        },
        {
            path: '/yueOder',
            name: 'yueOder',
            component: YueOder
        },
        {
            path: '/addMember',
            name: 'addMember',
            component: AddMember
        },
        {
            path: '/modPin',
            name: '修改pin码',
            component: ModPin
        },
        {
            path: '/dingcan',
            name: '订餐',
            component: Dingcan
        },
        {
            path: '/payOrder',
            name: '下单',
            component: Payorder
        },
        {
            path: '/regref',
            name: '充值',
            component: Regref
        },
        {
            path: '/memberReg',
            name: '注册',
            component: MemberReg
        },
        {
            path: '/sb',
            name: '十点多',
            component: sb
        },
        {
            path: '/rechargeInfo',
            name: '充值记录',
            component: RechargeInfo
        }, //userinfo
        {
            path: '/userinfo',
            name: '用户信息',
            component: Userinfo
        },
        {
            path: '/U404',
            name: '404',
            component: U404
        },
        {
            path: '/userface',
            name: 'userface',
            component: Userface
        },
    ]
    // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router