// axios.js
import axios from "axios";
import { Toast } from 'vant';

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.queue = {};
        this.appid = 'ap07f3e919592bacd8';
        this.Secretkey = '6563a009f5270b78e5ef757578659e18';
        this.authkey = '';
        this.queueArr = [];
        this.loading = [];
        this.time = 999;
        this.allRequest = [];
    }
    getInsideConfig() {
        return {
            baseURL: this.baseUrl
        };
    }
    destroy(url) {
        delete this.queue[url];
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }
    interceptors(instance, url) {
        // // console.log(this.baseUrl)
        let authkey = localStorage.getItem('authkey')
        let validityDate = Number(localStorage.getItem('validityDate'))
        let curTime = new Date().getTime()
            // console.log(curTime , validityDate ,curTime - validityDate) 
        if (validityDate == '' || authkey == '') {
            // console.log('rqwrqwe')
            window.postMessage('需要重新登陆', '*')
            return false
        }
        if (curTime >= validityDate && authkey) {
            // console.log('无效')
        } else {
            // console.log('在有效期内')
        }

        // 请求拦截
        instance.interceptors.request.use(
            config => {
                // console.log(config)
                let arr = this.queueArr;
                let authkey = ''
                    // 添加全局的loading...
                if (!Object.keys(this.queue).length) {
                    // Spin.show() // 不建议开启，因为界面不友好
                }
                this.queue[url] = true;
                if (config.method == 'post') {
                    config.data = {
                        authkey: this.appid + '|' + authkey,
                        // appid: this.appid,
                        ...config.data
                    }
                    config.url = config.url + '?authkey=' + this.appid + '|' + authkey;
                    // console.log(config)
                    arr.push(config);
                } else {
                    config.params = {
                        //appid: this.appid,
                        ...config.data

                    }
                }

                this.queueArr = arr
                    // console.log(config)
                this.allRequest.push(config);
                // console.log(this.queueArr)
                // console.log('请求开始之前')
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        // 响应拦截
        instance.interceptors.response.use(
            res => {
                // console.log(res)
                // this.sendLogin(res)
                // if (res.data.message && res.data.success == false) {
                //     Message.warning(res.data.message || 'No Message available')
                // }
                // console.log('请求结束')
                this.destroy(url);
                const { data, status } = res;
                // let resc = this.allRequest.filter(ele=>ele.url==url)
                let resc = [],
                    indexc = -1;
                this.allRequest.forEach((ele, index) => {
                        if (ele.url.indexOf(url) >= 0) {
                            resc.push(ele);
                            indexc = index
                        }
                    })
                    // console.log(url, res, resc)
                sendLogAll(url, res, resc);
                indexc >= 0 && this.allRequest.splice(indexc, 1);
                // this.allRequest = [];
                return { data, status };

            },
            error => {
                // console.log(error)
                this.destroy(url);
                let errorInfo = error.response;
                if (!errorInfo) {
                    const {
                        request: { statusText, status },
                        config
                    } = JSON.parse(JSON.stringify(error));
                    errorInfo = {
                        statusText,
                        status,
                        request: { responseURL: config.url }
                    };
                }
                // this.addErrorLog(errorInfo);
                return Promise.reject(error);
            }
        );
    }
    sendLogin(resa) {
        // // console.log(resa, resa.data, resa.res)
        let issuccess = '';
        let res = ''
        if (resa.success) {
            issuccess = resa.success;
        } else {
            res = resa.data ? resa : resa.res;
            issuccess = res.data.success || 0;
        }
        // // console.log(this.queueArr)
        if (this.queueArr.length == 0) {
            return false
        }
        console.log(res)
        let configc = this.queueArr.pop();
        // // console.log(configc)
        let url = configc.url.split('?')[0];
        // // console.log(configc,url)
        let route = url.split('/');
        // // console.log(route)
        // let title = ''
        // if (configc.data) {
        //     title = JSON.parse(configc.data).title || ''
        // } else {
        //     title = configc.params.title || ''
        // }
        let APILog = {
                'GetToken': '获取token',
                'GetMenuInfo': '获取菜单信息',
                'WriteLog': '操作日志',
                'WriteRole': '角色信息',
                'SystemLogin': '系统登录',
                'WriteMenuPermissions': '角色绑定菜单信息',
                'GetLogInfo': '获取操作日志信息',
                'GetRoleInfo': '获取角色信息',
                'GetAdminInfo': '获取操作员信息',
                'WriteAdminInfo': '操作员信息',
                'GetMotorcadeInfo': '获取车队信息',
                'WriteMotorcadeInfo': '车队信息',
                'GetCustomerVehicleInfo': '获取车辆信息',
                'WriteCustomerVehicleInfo': '写车辆信息',
                'xx': '测试账号',
                "GetCustomerOrderInfo": "获取订单信息",
                "WriteOrderDispatch": "订单派车",
                "GetOrderDispatchInfo": "获取订单派车信息",
                "ApprovalOrderDispatch": "审批订单派车信息",
                "ModAdminPwd": "修改操作员密码",
                "WriteMerchantInfo": '商户信息',
                "GetMerchantList": '获取商户信息',
                "AddTerminalInfo": '新增终端信息',
                "DelTerminalInfo": '删除终端信息',
                "ModTerminalInfo": '修改终端信息', //直接客户才用该功能
                "ModTerminalValidityDate": '修改终端有效期信息',
                "GetTerminalInfo": '获取终端信息',
                "GetUnauthorizedTerminalInfo": '待授权终端',
                "TerminalAuth": '终端授权',
                "TerminalUnbindingAuth": '终端解绑授权',
                "GetAuthMerchantInfo": "获取待授权商户信息",
                "WriteAreaInfo": "写区域信息",
                "GetAreaInfo": "获取区域信息",
                "WriteSiteInfo": "写场地信息",
                "GetSiteInfo": "获取场地信息",
                "WriteSportProjectInfo": "写运动项目信息",
                "GetSportProjectInfo": "获取运动项目信息",
                "GetDataDictionaryInfo": "获取数据字典信息",
                "WriteDataDictionaryInfo": "写数据字典信息",
                "WritePriceListInfo": "写价目表信息",
                "GetPriceListInfo": "获取价目表信息",
                "WriteFavouredPolicyInfo": "写项目优惠信息",
                "GetFavouredPolicyInfo": "获取项目优惠信息",
                "WriteMemberInfo": "写会员信息",
                "GetMemberInfo": "获取会员信息",
                "WriteTemporaryUserInfo": "写临时会员信息",
                "GetTemporaryUserInfo": "获取临时会员信息",
                "WriteRechargeInfo": "写充值信息",
                "UpdateRechargePayState": "更新充值状态信息",
                "WriteNetRechargeInfo": '写支付订单信息',
                "UpdateNetRechargePayState": '更新支付订单状态信息',
                "WriteOrderInfo": '下单信息',
                "UpdateOrderPayState": '更新订单支付状态信息',
                'GetRoleMenuPermissions': '获取角色菜单权限信息',
                'GetMotorcadeVehicleInfo': '获取车队匹配车辆信息',
                'ModPaymentParameters': '修改支付参数',
                'GetPaymentParameters': '获取支付参数信息',
                'CodeScanningPay': '扫码支付',
                'MemberCancellation': '会员注销',
                'ModSystemConfig': '修改系统参数',
                "GetSystemConfig": "获取商户系统参数信息",
                "GetRechargeInfo": "获取充值信息",
                "CodeScanningRefund": "扫码退款",
                "AddEntourage": "添加随行人员",
                "GetOrderInfo": "获取订单信息",
                "VerificationOrder": "核销订单",
            }
            /** 归纳 **/
        let obj = {
            merchantID: localStorage.getItem('MerchantID') || res.data.merchantID,
            CustomerID: localStorage.getItem('customerID') || res.data.customerID,
            CreateBy: localStorage.getItem('id') || res.data.id,
            LogNature: issuccess ? 1 : 0,
            LogIP: '127.0.0.1',
            LogType: '',
            LogObject: res.title,
        }
        let xx = APILog[route[route.length - 1]]
        console.log(xx)
        if (!xx) {
            return false
        }
        var reg = /Write|Get|Login|Approval|Update|Add|Verification|Code|Add|Cancellation/;
        let aaa = route[route.length - 1].match(reg)
        console.log(aaa)
        if (!aaa) {
            return false
        }
        // let LogObjectArr= ['客户信息', '供应商信息', '订单信息']
        // let LogTypeArr= ['登录', '新增', '修改', '删除','查询']


        if (configc.method === 'post') {
            let syncTypec = JSON.parse(configc.data)
            let objc = {
                '0': '新增',
                '1': '修改',
                '2': '删除'
            }
            obj.LogType = objc[syncTypec.syncType]
            if (JSON.parse(configc.data).approvalStatus) {
                obj.LogType = '修改'
            }
        }
        let LogTypeObj = { 'Login': '登录', 'Write': '新增', 'Get': '查询', 'Approval': '修改', 'Update': '更新', 'Add': "添加", 'Verification': '核销', Code: '扫码', 'Add': '添加', 'Cancellation': '注销' }
        obj.LogType = LogTypeObj[aaa[0]]
        if (res.ctx.search('新增') >= 0) { obj.LogType = '新增' }
        if (res.ctx.search('修改') >= 0) { obj.LogType = '修改' }
        if (res.ctx.search('删除') >= 0) { obj.LogType = '删除' }
        if (res.ctx.search('更新') >= 0) { obj.LogType = '更新' }
        if (res.ctx.search('注销') >= 0) { obj.LogType = '注销' }
        if (res.ctx.search('添加') >= 0) { obj.LogType = '添加' }
        if (res.ctx.search('付款') >= 0) { obj.LogType = '付款' }
        if (res.ctx.search('退款') >= 0) { obj.LogType = '退款' }
        if (res.objectType) {
            obj.LogType = res.objectType
        }
        /** 查询路由和菜单 **/
        // obj.LogObject = ( res.ctx + (obj.LogNature == 1 ? '成功' : '异常')).toString()
        obj.logContent = (localStorage.getItem('userName') + ' ' + res.ctx + (obj.LogNature == 1 ? '成功' : '异常')).toString();
        console.log(obj)
        return obj;
    }
    addErrorLog(err) {
        // // console.log(err);
        const { status } = err;
        switch (status) {
            case 510:
                // // console.log(Message);
                Toast("登录失效，请重新登录");
                break;
            case 500:
                Toast(err.data);
                break;
            case 404:
                err.data.Message === "No Message available" ?
                    Toast("请求错误") :
                    Toast(err.data.Message);
                break;
            default:
                MToast(err.data);
        }
    }
    async request(options) {
            await new Promise((resolve, reject) => {
                resolve(1)
            })

        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(options), options);
        this.interceptors(instance, options.url);
        return instance(options);
    }
}

// 每个请求的日志 info
function sendLogAll(urlc, msg, arr) {
    // console.log(config,window.g)
    // console.log(arr)
    arr.forEach(ele => {
        let { url, params, methods, method, data } = ele;
        let methodc = method || methods;
        let datac = JSON.stringify(data) || JSON.stringify(params)
            // // console.log('===============', urlc, ele)
            // console.log(url, methodc, datac)
            // console.log(msg.data)
        let issuccess = "";
        let res = "";
        if (msg.success) {
            issuccess = msg.success;
        } else {
            res = msg.data ? msg : msg.res;
            issuccess = res.data.success;
        }
        // console.log(issuccess, res)
        if (url.indexOf('/api/v1/OpenApi/AddEntourage') >= 0) {
            datac = '上传随行者名单'
        } else if (url.indexOf('/api/v1/WeChat/UploadMemberFacePic') >= 0) {
            datac = '上传预约下单头像'
        } else {

        }
        if (window.g.loglevenl == 3) {
            //日志存贮
            axios.request({
                method: "get",
                url: window.g.logBaseUrl + "/wechatlogrecord",
                params: {
                    url,
                    data: datac,
                    level: window.g.loglevenl,
                    type: issuccess == true ? "info" : "error",
                    res: JSON.stringify(msg.data).substring(0, 500),
                    method: methodc
                },
                success: (res) => {
                    // console.log(res);
                },
                fail: (err) => {
                    // // console.log(err);
                },
            });
        }
    });


}

export default HttpRequest;